import request from '@/service/request'

// 获取教学楼列表
export const getTeachBuildList = (params) => {
  return request.post('/app/teachBuild/page', params)
}

// 添加教学楼
export const addTeachBuild = (params) => {
  return request.post('/app/teachBuild/save', params)
}

// 删除教学楼
export const deleteTeachBuild = (params) => {
  return request.post('/app/teachBuild/del/' + params)
}

// 根据id获取教学楼信息
export const getTeachBuildById = (params) => {
  return request.post('/app/teachBuild/getById/' + params)
}

// 添加查询教学楼
/**
 * @param {String} campusId 校区id
 */
export const getTeachBuildListQuery = (params) => {
  return request.post('/app/teachBuild/list', params)
}

// 获取教学楼树
export const getTeachBuildTree = (params) => {
  return request.post('/app/teachBuild/tree', params)
}
// 获取门锁树
export const treeForClassroom = (params) => {
  return request.post('/app/teachBuild/treeForClassroom', params)
}

/*  根据楼栋id 获取锁树
* buildId { string } 楼栋id
* */
export const lockTreeForClassroomApi = (buildId) => {
  return request.post(`/app/teachBuild/treeForLock/${buildId}`)
}
