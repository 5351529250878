<template>
  <table-view v-loading="loading" class="week-teach-lock-table"
              :total="total"
              :query-info.sync="queryInfo"
              @reload-table="renderTable">
    <el-table :data="tableData" border stripe @selection-change="handleSelectChange">
      <el-table-column type="selection" width="50" align="center" />
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column label="教室类型" width="100">
        <template v-slot="{row}">
          {{ row.orgaType === 1 ? '宿舍' : (row.orgaType === 2 ? '教学楼' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="teachBuildName" label="所在教学楼" width="100" />
      <el-table-column label="绑定门锁" width="150">
        <template v-slot="{row}">
          <span :class="{'is-out-of-date':getOutOfDate(row)}">{{ row.teachRoomName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="管理员" width="120">
        <template v-slot="{row}">
          {{ row.stuName ? row.stuName + '(学生)' : row.realName ? row.realName + '(教职工)' : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="featureCode" label="设备特征值" width="180" />
      <el-table-column prop="startTime" label="开始时间" width="180">
        <template v-slot="{row}">
          <span :class="{'is-out-of-date':getOutOfDate(row)}">{{ row.startTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="180">
        <template v-slot="{row}">
          <span :class="{'is-out-of-date':getOutOfDate(row)}">{{ row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="160" />
      <el-table-column label="操作" fixed="right" width="160">
        <template v-slot="{ row }">
          <el-button v-permission="['class:lock:edit']" type="primary" size="mini"
                     @click="showDialog(row.id,row)">编辑
          </el-button>
          <el-button v-permission="['class:lock:del']" type="danger" size="mini"
                     @click="del(deleteTeachLock,row.id,`${row.realName || row.stuName} 对 ${row.teachBuildName}${row.teachRoomName} 的教室门锁分配`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-lock ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
              time-type="dateTime"
              @on-close="renderTable" />
  </table-view>
</template>

<script>
import { deleteTeachLock, pageTeachLockByDateTimeApi, batchDelTeachLockApi } from '@/api/teachLock'
import tableView from '@/vue/mixins/table-view'
import addLock from './addTeachLock.vue'

export default {
  name: 'dateTimeTeachLockTable',
  mixins: [tableView],
  components: {
    addLock
  },
  props: {
    isLate: Number || null,
    parentData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      queryInfo: {
        buildId: null, // 来自 parentData
        campusId: null, // 来自 parentData
        classroomId: null, // 来自 parentData
        floorNo: null, // 来自 parentData
        isLate: null // 是否过期 0-未过期 1-已过期
      },
      rowCheckList: []
    }
  },
  watch: {
    parentData: function () {
      this.renderTable(1)
    }
  },
  methods: {
    deleteTeachLock, // 删除api
    async renderTable (pageNum) {
      if (this.parentData?.classroomId) {
        this.queryInfo.buildId = this.parentData.buildId
        this.queryInfo.campusId = this.parentData.campusId
        this.queryInfo.classroomId = this.parentData.classroomId
        this.queryInfo.floorNo = this.parentData.floorNo
        this.queryInfo.isLate = this.isLate
        await this.getTableData(pageTeachLockByDateTimeApi, pageNum)
      }
    },
    // 判断是否过期
    getOutOfDate (row) {
      if (!row.endTime) {
        row.outFlag = true
        return
      }
      let _endTime = row.endTime.replace(/-/g, '/')
      _endTime = new Date(_endTime).getTime()
      const _today = Date.now() - 8.64e7
      if (_endTime < _today) {
        row.outFlag = false
      }
      // 判断使用权限是否已过期
      return _endTime < _today
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    },
    // 批量删除
    batchDel () {
      if (!this.rowCheckList.length) {
        this.$message.warning('请先勾选需要批量操作的数据！')
        return
      }
      this.$confirm(`是否确认删除选中的${this.rowCheckList.length}个用户的门锁使用权限？`).then(async () => {
        this.loading = true
        try {
          await batchDelTeachLockApi({ ids: this.rowCheckList })
          this.$message.success('批量删除执行成功！')
          await this.renderTable()
        } catch (e) {
        } finally {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
