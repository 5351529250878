<template>
  <div class="class-room-selector">
    <div class="flex class-room-selector_header">
      <ElInput class="search-input" v-model="keyword" placeholder="搜索" clearable>
        <template #prefix>
          <span class="el-icon-search"></span>
        </template>
      </ElInput>
      <el-checkbox :disabled="!!visibleOnlyChecks" class="ml-1" true-label="isCheckAll" v-model="isCheckAll"
                   @change="handleCheckAll"
                   style=" width: 60px;">全选
      </el-checkbox>
      <el-checkbox :disabled="!!keyword" class="ml-1" true-label="visibleOnlyChecks" v-model="visibleOnlyChecks"
                   @change="handleShowChecks"
                   style=" width: 140px;">
        <tool-tips-group tips-content="存在【搜索关键字】时，此处禁用。"><span
          style="vertical-align: -1px;">只显示已勾选</span></tool-tips-group>
      </el-checkbox>
    </div>
    <div class="oh" style="height: 260px;">
      <el-scrollbar style="height:277px;width: 100%;">
        <el-tree
          ref="treeRef"
          :data="treeData"
          node-key="id"
          show-checkbox
          default-expand-all
          :props="treeProps"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          @check="handleCheck" />
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { lockTreeForClassroomApi } from '@/api/teachBuild'
import dormTree from '@/vue/mixins/dorm-tree'

export default {
  name: 'classRoomSelector',
  mixins: [dormTree],
  props: {
    checkList: {
      type: Array,
      default: () => ([])
    },
    allotInfo: { // 被分配者的信息
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      buildId: ''
    }
  },
  methods: {
    async renderTree (buildId) {
      try {
        const res = await lockTreeForClassroomApi(buildId)
        const _tree = res.data
        this.setChildren(_tree)
        this.treeData = _tree
      } catch (e) {
      }
    },
    /* 把 object中 的array找出来 克隆到children字段内 插入原数组
    *  如果接口改动, 单个object内拥有超过一个arrray,此方法会报错
    * */
    setChildren (arr) {
      if (!arr.length) return
      for (const item of arr) {
        for (const key of Object.keys(item)) {
          if (Array.isArray(item[key]) && key !== 'children') {
            Object.assign(item, { children: [...item[key]] })
            item.children.map(child => {
              Object.assign(child, { parentId: item.id })
            })
            this.setChildren(item.children)
            break
          }
        }
      }
    },
    // 搜索条过滤数据
    filterNode (value, data, node) {
      if (!value) {
        if (data.disabled) {
          data.disabled = false
          node.visible = false
        }
        return true
      } else {
        if (!node.data.lockFeatureCode) {
          data.disabled = true
          node.visible = false
        }
        if (value === 'visibleOnlyChecks') {
          // value =visibleOnlyChecks 时, 过滤已勾选的门锁数据
          return node.checked
        } else {
          this.visibleOnlyChecks = false
          return data.label.indexOf(value) !== -1
        }
      }
    },
    // 全选
    handleCheckAll (val) {
      // 遍历获取所有显示的宿舍id
      const _ids = []
      this.$refs.treeRef.data.map(build => {
        const _node = this.$refs.treeRef.getNode(build.id)
        if (_node?.visible && _node.childNodes.length) {
          this.recursiveRooms(_node.childNodes, _ids)
        }
      })
      if (val) {
        this.rowCheckList = [...new Set(this.rowCheckList.concat(_ids))]
      } else {
        this.rowCheckList = this.rowCheckList.filter(_id => !_ids.includes(_id))
      }
      this.$refs.treeRef.setCheckedKeys(this.rowCheckList)
      this.$emit('update:checkList', this.rowCheckList)
    },
    // 递归获取 visible 状态的所有门锁
    recursiveRooms (arr = [], result = []) {
      if (!arr.length) return
      arr.map(node => {
        if (node.visible) {
          if (node.data.lockFeatureCode) {
            result.push(node.data.id)
            return result
          } else if (node.childNodes.length) {
            this.recursiveRooms(node.childNodes, result)
          }
        }
      })
    },
    // 获取选中的门锁
    handleCheck (node, checkedNodes) {
      const _checkList = []
      if (checkedNodes.checkedNodes.length) {
        for (const item of checkedNodes.checkedNodes) {
          // 存在lockFeatureCode =为门锁
          if (item.lockFeatureCode) {
            _checkList.push(item.id)
          }
        }
      }
      this.rowCheckList = [...new Set(_checkList)]
      this.$emit('update:checkList', this.rowCheckList)
    }
  }
}
</script>

<style lang="scss">
.class-room-selector {
  @include flex-column;
  border: 1px solid var(--border-light);

  .el-icon-search{
    line-height: 40px;
  }
  .class-room-selector_header {
    border-bottom: 1px solid var(--border-light);
  }

  .search-input {
    @include flex-1;
    border-right: 1px solid var(--border-light);

    .el-input__inner {
      border: 0;
    }

    .el-input__wrapper {
      box-shadow: none;
    }
  }

  .el-tree {
    > .el-tree-node {
      height: auto;
      overflow: hidden;

      > .el-tree-node__children {
        > .el-tree-node {
          height: auto;
          overflow: hidden;

          > .el-tree-node__children {
            width: 100%;
            @include flex;
            flex-direction: row; /* 指定主轴方向为水平 */
            flex-wrap: wrap; /* 设为多行换行 */

            > .el-tree-node {
              padding-left: 4%;
              width: 29.3%;
              margin-bottom: 1em;
            }

            .el-tree-node__expand-icon {
              display: none;
            }
          }
        }
      }

    }
  }

}
</style>
