<template>
  <table-view class="teach-lock" v-loading="loading" :show-page="false">
    <template #header>
      <div>
        <el-form :model="currentData" inline label-width="68px" style="display: inline-block;">
          <el-form-item label="校区" label-width="40">
            <el-select
              v-model="currentData.campusId"
              @change="treeFilter"
              clearable
              filterable
            >
              <el-option v-for="{campusName,id} in campusList" :key="id" :label="campusName" :value="id" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间类型">
            <el-radio-group v-model="timeType" @change="handleTimeType">
              <el-radio-button label="week">按周</el-radio-button>
              <el-radio-button label="dateTime">按日期时间</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="header-button fr">
          <el-button v-permission="['class:lock:edit']" type="primary" size="small" @click="showDialog">分配教室门锁
          </el-button>
        </div>
      </div>
    </template>
    <el-card style="min-height: 85vh">
      <el-row>
        <!-- 树形图 -->
        <el-col :span="4">
          <el-scrollbar>
            <div
              v-for="item in curTree"
              :key="item.id"
              style="max-height: 70vh"
            >
              <el-tree
                accordion
                :data="item.treeItem"
                node-key="id"
                highlight-current
                @node-click="handleNodeClick"
                :default-expanded-keys="expandedKeys"
              />
            </div>
          </el-scrollbar>
        </el-col>
        <!-- 表格 -->
        <el-col :span="20">
          <el-row justify="space-between">
            <el-col class="title" :span="20">
              <div style="display: inline-block;" v-if="curFloorShow">
                <span>{{ currentData.buildLabel }}</span>
                <span>第{{ currentData.floorNo }}层{{ currentData.label }}室</span>
              </div>
              <div v-else>
                <span>{{ addData.teachBuildName }}</span>
                <span>{{ addData.teachRoomName }}</span>
              </div>

              <el-radio-group v-if="timeType==='dateTime'" class="ml-1" size="small" v-model="isLate"
                              @change="handleTimeType('dateTime')">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="0">未过期</el-radio-button>
                <el-radio-button :label="1">已过期</el-radio-button>
              </el-radio-group>
              <el-radio-group v-else class="ml-1" size="small" v-model="startUpFlag" @change="handleTimeType('week')">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button label="1">已启用</el-radio-button>
                <el-radio-button label="0">已禁用</el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col class="title font-right" :span="3">
              <el-button v-if="timeType==='dateTime'" :disabled="isLate===1" size="small" type="danger" plain @click="()=>$refs.dateTimeTableRef.batchDel()">批量删除</el-button>
            </el-col>
          </el-row>
          <el-col>
            <week-teach-lock-table v-if="timeType==='week'" ref="weekTableRef" :parent-data="currentData"
                                   :start-up-flag="startUpFlag" />
            <date-time-teach-lock-table v-if="timeType==='dateTime'" ref="dateTimeTableRef"
                                        :parent-data="currentData" :is-late="isLate" />
          </el-col>
        </el-col>
      </el-row>
    </el-card>
    <add-lock ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
              :time-type="timeType"
              @on-close="handleTimeType(timeType)" />
  </table-view>
</template>

<script>
import { treeForClassroom } from '@/api/teachBuild'
import { getCampusList } from '@/api/campus'
import tableView from '@/vue/mixins/table-view'
import DateTimeTeachLockTable from '@/views/pages/teachBuildManage/classroom-lock/component/dateTimeTeachLockTable.vue'
import WeekTeachLockTable from '@/views/pages/teachBuildManage/classroom-lock/component/weekTeachLockTable.vue'
import addLock from '@/views/pages/teachBuildManage/classroom-lock/component/addTeachLock.vue'

export default {
  components: {
    addLock,
    WeekTeachLockTable,
    DateTimeTeachLockTable
  },
  mixins: [tableView],
  data () {
    return {
      isLate: null, // 是否过期 1-是，0-否 2-全部
      visibleHistoryDialog: false, // 门锁历史记录 dialog
      expandedKeys: [],
      addData: { // 用于展示数据
        teachBuildName: '',
        teachRoomName: ''
      },
      currentData: {
        campusId: '',
        buildId: '',
        buildLabel: '',
        floorNo: '',
        floorId: '',
        classroomId: '',
        label: ''
      },
      campusList: [],
      curFloorShow: true, // 当前楼层信息
      timeType: 'week',
      startUpFlag: null,
      teachLockList: [], // 按日期时间的门锁列表
      curTree: []
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    // 获取教学楼的树
    init () {
      this.getCampusList().then(() => {
        treeForClassroom()
          .then((res) => {
            this.teachBuildTree = res.data
            this.teachBuildTree.map((item) => {
              item.children = item.floors
              item.children.map((i) => {
                i.children = i.classrooms
              })
            })
          })
          .then(() => {
            // 初始化数据
            this.initData()
            // 校区选择
            this.treeFilter()
          })
      })
    },
    // 初始化数据
    initData () {
      this.currentData = {
        campusId: this.teachBuildTree[0].campusId,
        buildId: this.teachBuildTree[0].id,
        buildLabel: this.teachBuildTree[0].label,
        floorNo: '1',
        floorId: this.teachBuildTree[0].children[0].id,
        classroomId: this.teachBuildTree[0].children[0].classrooms[0].id,
        label: this.teachBuildTree[0].children[0].classrooms[0].label
      }
    },
    // 获取校区信息
    getCampusList () {
      return getCampusList({}).then((res) => {
        this.campusList = res.data.list
      })
    },
    // 校区选择
    treeFilter () {
      this.curTree = this.campusList.filter((item) => {
        return item.id === this.currentData.campusId
      })
      if (this.curTree.length) {
        this.curTree[0].treeItem = this.teachBuildTree.filter((item) => {
          return item.campusId === this.currentData.campusId
        })

        if (this.curTree[0]?.treeItem?.length) {
          // 默认展开结点
          this.expandedKeys = [this.curTree[0].treeItem[0].children[0].id]
          if (this.curTree[0]?.treeItem[0]?.floors[0]?.classrooms.length) {
            const _curTreeBulid = this.curTree[0]?.treeItem[0]
            const _curFloor = _curTreeBulid?.floors[0]
            const _curClassRoom = _curFloor?.classrooms[0]
            this.currentData = {
              campusId: this.curTree[0].id,
              buildId: _curTreeBulid?.id,
              buildLabel: _curTreeBulid?.label,
              floorNo: _curFloor.floorNo.toString(),
              floorId: _curFloor.id,
              classroomId: _curClassRoom.id,
              label: _curClassRoom.label
            }
            // 如果子级table已存在（if用来防止重复刷新），则请求table数据
            if (this.$refs.weekTableRef.parentData?.classroomId) {
              this.handleTimeType(this.timeType)
            }
          }
        }
      }
    },
    // 点击树节点筛选
    handleNodeClick (data, node) {
      this.curFloorShow = true
      if (node.level === 3) {
        this.currentData = {
          campusId: node.parent.parent.data.campusId,
          buildId: node.parent.parent.data.id,
          buildLabel: node.parent.parent.data.label,
          floorNo: node.parent.data.floorNo,
          floorId: node.parent.id,
          classroomId: data.id,
          label: data.label
        }
        this.$nextTick(() => {
          this.handleTimeType(this.timeType)
        })
      }
    },
    handleTimeType (val) {
      switch (val) {
        case 'week':
          this.$refs.weekTableRef.renderTable(1)
          break
        case 'dateTime':
          this.$refs.dateTimeTableRef.renderTable(1)
          break
      }
    },
    showDialog () {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = '分配'
        this.$refs.dialogRef.title = this.currentData
        // 深拷贝 原始formData
        this.$refs.dialogRef.setInitData()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.teach-lock {
  .is-out-of-date {
    color: #898989;
    text-decoration: line-through;
  }
}

.filter {
  margin-top: 22px;
}

.title {
  margin: 10px 0;
  font-weight: 700;
}
</style>
