<template>
  <el-dialog
    :class="['teach-lock-dialog',{'dialog-center':!id}]"
    :title="`${preTitle}教室门锁权限${id?'-'+title.teachBuildName+title.teachRoomName:''}`"
    :visible.sync="show"
    :close-on-click-modal="false"
    v-loading="loadData.loading"
    width="720px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <template v-if="!id">
        <el-form-item label="用户类型" prop="userType">
          <el-radio-group v-model="formData.userType" size="small">
            <el-radio-button :label="2">学生</el-radio-button>
            <el-radio-button :label="3">教职工</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- 选了userType 才能开始远程搜索 -->
        <el-form-item label="搜索用户" prop="userId">
          <tool-tips-group is-flex
                           tips-content="选择用户类型后，此项转为可填。<br/>名字搜索最少输入2个汉字，身份证/录取编号搜索最少输入6位数字。<br/>只能搜索所管辖学院的学生。">
            <div class="flex">
              <el-autocomplete
                class="flex-1"
                :disabled="!formData.userType||userSelectDisabled"
                v-model="formData.userName"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入用户名、身份证号、学号(数字搜索最少6位数)"
                clearable
                @select="handleSelect" />
              <el-button @click="clearUserSelect">清空</el-button>
            </div>
          </tool-tips-group>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-radio-group v-model="timeType" size="small" @change="handleTimeType">
            <el-radio-button label="week">按周</el-radio-button>
            <el-radio-button label="dateTime">按日期时间</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-row v-if="timeType==='week'">
          <el-col :span="24">
            <el-form-item label="周期" prop="weeks">
              <el-checkbox-group v-model="formData.weeks" @change="handleWeeks">
                <el-checkbox v-for="{paramValue, paramName} in weeksList" :key="paramValue"
                             :label="Number(paramValue)">{{ paramName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <!--    按周次为周期 start    -->
          <el-col :span="12">
            <el-form-item label="开始时间" prop="startTime">
              <el-time-select
                v-model="formData.startTime"
                placeholder="选择开始时间"
                :picker-options="{
                  start: '06:00',
                  step: '00:15',
                  end: '23:45'
                }" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endTime">
              <el-time-select
                v-model="formData.endTime"
                placeholder="选择开始时间"
                :picker-options="{
                  start: '06:15',
                  step: '00:15',
                  end: '23:59',
                  minTime: formData.startTime
                }" />
            </el-form-item>
          </el-col>
        </el-row>
        <!--    按周次为周期 end    -->
        <!--    按日期时间为周期 start    -->
        <el-row v-if="timeType==='dateTime'">
          <el-col :span="12">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                v-model="formData.startTime"
                type="datetime"
                placeholder="选择开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="comptStartTime" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                v-model="formData.endTime"
                type="datetime"
                placeholder="选择结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="comptEndTime" />
            </el-form-item>
          </el-col>
        </el-row>
        <!--    按日期时间为周期 end    -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="所在校区" prop="campusId">
              <el-select v-model="roomInfo.campusId" @change="handleTeachBuildList">
                <el-option
                  v-for="{campusName, id} in campusList"
                  :label="campusName"
                  :value="id"
                  :key="id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在教学楼" prop="teachBuildName">
              <el-select v-model="roomInfo.buildId" :disabled="!teachBuildList.length" @change="handleClassRoomTree">
                <el-option
                  v-for="{label, id} in teachBuildList"
                  :label="label"
                  :value="id"
                  :key="id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">
          <tool-tips-group tips-content="请先选择【所在校区】和【所在教学楼】">所在教室</tool-tips-group>
        </el-divider>
        <el-form-item prop="sysUserLocks" label-width="0">
          <class-room-selector ref="classRoomTreeRef" :allot-info="allotInfo"
                               :check-list.sync="formData.orgaIds" />
        </el-form-item>
      </template>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>

</template>

<script>
import { getCampusList } from '@/api/campus'
import { getTeachBuildTree } from '@/api/teachBuild'
import { saveBatchClassLockAPi, saveClassLockAPi, saveBatchLockByWeekAPi, saveLockByWeekAPi } from '@/api/teachLock'
import dialog from '@/vue/mixins/dialog'
import ClassRoomSelector from '@/views/pages/teachBuildManage/classroom-lock/component/classRoomSelector.vue'
import userAutocomplete from '@/vue/mixins/user-autocomplete'

export default {
  components: { ClassRoomSelector },
  mixins: [dialog, userAutocomplete],
  props: {
    timeType: {
      type: String,
      default: 'week'
    }
  },
  data () {
    return {
      formData: {
        id: null,
        orgaId: '',
        orgaType: '2', // 教室类型 为2
        teachBuildName: '',
        teachRoomName: '',
        startTime: '',
        endTime: '',
        userId: '',
        remarks: '',
        userType: '',
        userName: '',
        orgaIds: [],
        weeks: [], // 前端用于存储周几数组
        week: ''
      },
      weeksList: [
        {
          paramName: '周一',
          paramValue: 1
        },
        {
          paramName: '周二',
          paramValue: 2
        },
        {
          paramName: '周三',
          paramValue: 3
        },
        {
          paramName: '周四',
          paramValue: 4
        },
        {
          paramName: '周五',
          paramValue: 5
        },
        {
          paramName: '周六',
          paramValue: 6
        },
        {
          paramName: '周日',
          paramValue: 7
        }
      ],
      roomInfo: {
        campusId: '',
        userName: '',
        buildId: '',
        floorNo: '',
        roomId: ''
      },
      campusList: [], // 校区列表
      AllTeachBuildList: [], // 所有教学楼
      teachBuildList: [], // 教学楼列表
      rules: {
        userType: {
          required: true,
          message: '请选择用户类型',
          trigger: 'blur'
        },
        startTime: {
          required: true,
          message: '请选择开始时间',
          trigger: 'blur'
        },
        endTime: {
          required: true,
          message: '请选择结束时间',
          trigger: 'blur'
        },
        userId: {
          required: true,
          message: '请输入中文/身份证/学号远程搜索和选择用户',
          trigger: 'blur'
        }
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.getCampusList(),
      this.getTeachBuildTree()
    ])
    await this.$nextTick(() => {
      if (this.id) {
        // 编辑状态时，日期时间类型的需要传时间参数
        ({
          id: this.formData.id,
          remarks: this.formData.remarks
        } = this.title)
        if (this.timeType === 'dateTime') {
          this.formData.startTime = this.title.startTime
          this.formData.endTime = this.title.endTime
        }
      }
      this.setInitData()
    })
  },
  computed: {
    allotInfo () { // 锁分配的信息,用于插入门锁组件内
      return {
        orgaType: '2', // 2-教室类型
        startTime: this.formData.startTime || '',
        endTime: this.formData.endTime || '',
        userId: this.formData.userId || '',
        remarks: this.formData.remarks || ''
      }
    },
    comptStartTime () { // 开始日期最早在当日
      let _endTime = 0
      if (this.formData.endTime) {
        _endTime = this.formData.endTime.replace(/-/g, '/')
        _endTime = new Date(_endTime).getTime()
      }
      return {
        disabledDate: time => {
          const _today = Date.now() - 8.64e7
          if (_endTime) {
            return time.getTime() > _endTime
          } else {
            return time.getTime() < _today
          }
        }
      }
    },
    comptEndTime () { // 结束日期不能早于开始日期和当日
      let _startTime = 0
      if (this.formData.startTime) {
        _startTime = this.formData.startTime.replace(/-/g, '/')
        _startTime = new Date(_startTime).getTime()
      }
      return {
        disabledDate: time => {
          if (_startTime) {
            return time.getTime() < (_startTime - 8.64e7)
          } else {
            const _today = Date.now() - 8.64e7
            return time.getTime() < _today
          }
        }
      }
    }
  },
  methods: {
    // 远程搜索 获取用户id
    handleSelect (user) {
      // 2-学生
      if (+this.formData.userType === 2) {
        this.formData.userId = user.userId
      } else {
        this.formData.userId = user.id
      }
      this.userSelectDisabled = true
      this.$refs.form.validateField('userId')
    },
    // 获取校区信息
    getCampusList () {
      return getCampusList({}).then((res) => {
        this.campusList = res.data.list
      })
    },
    // 获取教学楼列表
    async getTeachBuildTree () {
      try {
        const res = await getTeachBuildTree()
        this.AllTeachBuildList = res.data
      } catch (e) {
      }
    },
    // 根据campusId 过滤教学楼
    handleTeachBuildList (val) {
      this.roomInfo.buildId = ''
      this.clearList(this.teachBuildList)
      this.clearList(this.$refs.classRoomTreeRef.treeData)
      if (val) {
        this.teachBuildList = this.AllTeachBuildList.filter(({ campusId }) => campusId === val)
      }
    },
    // 根据选择的教学楼筛选楼层树
    handleClassRoomTree (val) {
      this.$refs.classRoomTreeRef.buildId = val
      this.$refs.classRoomTreeRef.renderTree(val)
    },
    handleTimeType () {
      this.formData.startTime = ''
      this.formData.endTime = ''
      this.formData.weeks = []
      this.formData.week = ''
    },
    handleWeeks (weeks) {
      if (weeks?.length) {
        this.$nextTick(() => {
          this.formData.week = weeks.join(',')
        })
      } else {
        this.formData.week = ''
      }
    },
    // 添加、编辑门锁
    async save () {
      // 先获取选中的教室门锁
      if (!this.id) {
        if (!this.formData.orgaIds.length) {
          this.$message.error('请先选择要分配的教室门锁!')
          return
        }
      }
      // 判断表单是否有更新
      this.compareData()
      if (!this.updateFlag) {
        this.$message.error('表单未被修改!')
        return
      }
      await this.$refs.form.validate((valid) => {
        if (valid) {
          const _msg = this.id ? '是否保存修改？' : `<p class="confirm-message">是否确认批量分配这 <b class="bg-primary">${this.formData.orgaIds.length}个</b> 教室门锁的使用权限给 <b class="primary">${this.formData.userType === '2' ? '学生' : '用户'}-${this.formData.userName}</b>？`
          const _successMsg = this.id ? '修改成功!' : '分配成功!'
          this.$confirm(`${_msg}`, '保存提示', {
            dangerouslyUseHTMLString: true
          }).then(async () => {
            const _formData = {}
            Object.keys(this.formData).filter(key => (Object.prototype.toString.call(this.formData[key]) !== '[object Array]' && !!this.formData[key]) || !!this.formData[key]?.length || this.formData[key] === 0 || this.formData[key] !== this.initData[key]).forEach(k => {
              _formData[k] = this.formData[k]
            })
            try {
              if (this.timeType === 'week') {
                // 按周次为周期分配
                if (this.id) {
                  // 只修改备注
                  await saveLockByWeekAPi({
                    id: this.id,
                    startTime: this.title.startTime,
                    endTime: this.title.endTime,
                    remarks: this.formData.remarks
                  })
                } else {
                  // 批量分配  需要加入 startUpFlag=1（启用门锁权限）字段
                  Object.assign(this.formData, { startUpFlag: 1 })
                  await saveBatchLockByWeekAPi(this.formData)
                }
              } else {
                // 按日期时间分配
                if (this.id) {
                  const _changeData = {
                    id: this.id,
                    remarks: this.formData.remarks,
                    startTime: this.title.startTime,
                    endTime: this.title.endTime
                  }
                  await saveClassLockAPi(_changeData)
                } else {
                  await saveBatchClassLockAPi(this.formData)
                }
              }
              this.$emit('on-close')
              this.$message.success(_successMsg)
              this.close()
            } catch (e) {
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.teach-lock-dialog {
  &.dialog-center {
    .el-dialog {
      margin-top: 5vh !important;
    }
  }
}
</style>
