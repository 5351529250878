<template>
  <table-view v-loading="loading" class="week-teach-lock-table"
              :total="total"
              :query-info.sync="queryInfo"
              @reload-table="renderTable">
    <el-table :data="tableData" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column label="教室类型" width="100">
        <template v-slot="{row}">
          {{ row.orgaType === 1 ? '宿舍' : (row.orgaType === 2 ? '教学楼' : '') }}
        </template>
      </el-table-column>
      <el-table-column prop="teachBuildName" label="所在教学楼" width="100" />
      <el-table-column label="绑定门锁" width="150">
        <template v-slot="{row}">
          <span :class="{'is-out-of-date':!!+row.startUpFlag}">{{ row.teachRoomName }}</span>
        </template>
      </el-table-column>
      <el-table-column width="120" label="管理员">
        <template v-slot="{row}">
          {{ row.stuName ? row.stuName + '(学生)' : row.realName ? row.realName + '(教职工)' : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="featureCode" label="设备特征值" width="180" />
      <el-table-column label="是否启用" width="120" align="center">
        <template v-slot="{row}">
          <el-switch
            v-model="row.startUpFlag"
            active-color="var(--color-success)"
            active-value="1"
            active-text="是"
            inactive-text="否"
            inactive-value="0"
            @change="handleStartUpFlag($event,row)"
          />
        </template>
      </el-table-column>
      <el-table-column label="周" width="180">
        <template v-slot="{row}">
          {{ row.week | toWeekChinese }}
        </template>
      </el-table-column>
      <el-table-column label="时间段" width="120" align="center">
        <template v-slot="{row}">
          {{ row.startTime }}-{{ row.endTime }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="160" />
      <el-table-column label="操作" fixed="right" width="160">
        <template v-slot="{ row }">
          <el-button v-permission="['class:lock:edit']" type="primary" size="mini"
                     @click="showDialog(row.id,row)">编辑
          </el-button>
          <el-button v-permission="['class:lock:del']" type="danger" size="mini"
                     @click="del(delLockByWeekAPi,row.id,`${row.realName || row.stuName} 对 ${row.teachBuildName}${row.teachRoomName} 的教室门锁分配`)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-lock ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
              time-type="week" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { delLockByWeekAPi, pageLockByWeekAPi, saveLockByWeekAPi } from '@/api/teachLock'
import tableView from '@/vue/mixins/table-view'
import addLock from './addTeachLock.vue'

export default {
  name: 'weekTeachLockTable',
  mixins: [tableView],
  components: {
    addLock
  },
  filters: {
    toWeekChinese (val) {
      if (!val) return
      const _chineseList = ['周零', '周一', '周二', '周三', '周四', '周五', '周六', '周日']
      return val.split(',').map(item => _chineseList[+item]).join('，')
    }
  },
  props: {
    startUpFlag: String || null,
    parentData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      queryInfo: {
        buildId: null, // 来自 parentData
        campusId: null, // 来自 parentData
        classroomId: null, // 来自 parentData
        floorNo: null, // 来自 parentData
        startUpFlag: null // 是否启用 ‘0’-禁用 ‘1’-已启用
      }
    }
  },
  watch: {
    parentData: function (val) {
      if (val?.classroomId) {
        this.renderTable(1)
      }
    }
  },
  methods: {
    delLockByWeekAPi, // 删除api
    async renderTable (pageNum) {
      if (this.parentData?.classroomId) {
        this.queryInfo.buildId = this.parentData.buildId
        this.queryInfo.campusId = this.parentData.campusId
        this.queryInfo.classroomId = this.parentData.classroomId
        this.queryInfo.floorNo = this.parentData.floorNo
        this.queryInfo.startUpFlag = this.startUpFlag
        await this.getTableData(pageLockByWeekAPi, pageNum)
      }
    },
    handleStartUpFlag (val, row) {
      this.$confirm(`是否确认${+val ? '启用' : '禁用'}${row.realName || row.stuName}对${row.teachBuildName}${row.teachRoomName}的门锁权限？`).then(async () => {
        try {
          await saveLockByWeekAPi({
            id: row.id,
            startUpFlag: val,
            startTime: row.startTime,
            endTime: row.endTime
          })
          this.$message.success(`${+val ? '启用' : '禁用'}成功！`)
        } catch (e) {
        }
      }).catch(() => {
        this.$message.info('已取消')
        row.startUpFlag = +val ? '0' : '1'
      })
    }
  }
}
</script>
