import request from '@/service/request'
// 教室门锁权限分配 用户分配

// 按时间周期分配门锁 page接口
export const pageTeachLockByDateTimeApi = (params) => {
  return request.post('/app/sysUserLock/page/', params)
}

// 根据ID删除
export const deleteTeachLock = (params) => {
  return request.post('/app/sysUserLock/del/' + params)
}
/* 根据IDs 批量删除
*  @params {array} ids
* */
export const batchDelTeachLockApi = (params) => {
  return request.post('/app/sysUserLock/delBatch', params)
}

// 根据ID获取
export const getTeachLockById = (params) => {
  return request.post('/app/sysUserLock/getById/' + params)
}

// 查询
export const getTeachLockList = (params) => {
  return request.post('/app/sysUserLock/list', params)
}

// 分页查询
export const getTeachLockListPage = (params) => {
  return request.post('/app/sysUserLock/page', params)
}

/*  单个 门锁分配备注 保存编辑
*  id {string} 门锁id
*  remarks {string} 备注
* */
export const saveClassLockAPi = (params) => {
  return request.post('/app/sysUserLock/save', params)
}

/*  批量分配教室门锁 保存 按日期时间
*  startTime {string} 开始时间 yyyy-MM-dd HH:mm:ss
*  endTime {string} 结束时间 yyyy-MM-dd HH:mm:ss
* * orgaIds {Array} 教室门锁id
*  */
export const saveBatchClassLockAPi = (params) => {
  return request.post('/app/sysUserLock/saveBatch', params)
}

/*  批量分配教室门锁 按周 单独保存
*  remarks {string} 备注
*  */
export const saveLockByWeekAPi = (params) => {
  return request.post('/app/sysUserLockRule/save', params)
}

/*  批量分配教室门锁 按周 批量保存
*  startTime {string} 开始时间 HH:mm
*  endTime {string} 结束时间 HH:mm
*  week {string} 周几
*  orgaIds {Array} 教室门锁id
*  */
export const saveBatchLockByWeekAPi = (params) => {
  return request.post('/app/sysUserLockRule/saveBatch', params)
}

/* 门锁规则 按周为为周期分配 page
* */
export const pageLockByWeekAPi = (params) => {
  return request.post('/app/sysUserLockRule/page', params)
}

/* 门锁规则 按周为为周期分配 删除
*  id {string} 门锁id
* */
export const delLockByWeekAPi = (id) => {
  return request.post(`/app/sysUserLockRule/del/${id}`)
}
